import React, { useEffect } from 'react'
import classes from './ContactForm.module.scss'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
// import ReCAPTCHA from 'react-google-recaptcha'

import Input from '../../../components/UI/Input/Input'

import 'react-toastify/dist/ReactToastify.css'
import useFormValidation from '../FormLogic/useFormValidation'
import validateAuth from '../FormLogic/validateAuth'

const ContactForm = props => {
  const INITIAL_STATE = {
    email: '',
    name: '',
    surname: '',
    phone: '',
    message: '',
  }
  const {
    handleChange,
    values,
    handleSubmit,
    handleBlur,
    errors,
    submitted,
    sent,
  } = useFormValidation(INITIAL_STATE, validateAuth)

  const notify = () =>
    toast.success(props.thank, {
      className: classes.toast,
      position: 'top-right',
      autoClose: 4000,
      marginTop: 120,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      closeButton: false,
    })

  // const recaptchaRef = React.createRef()

  const hangleSubmitAll = event => {
    handleSubmit(event)
    // recaptchaRef.current.execute()
  }
  return (
    <div className={classes.contactForm}>
      <form
        className={classes.formContainer}
        onSubmit={hangleSubmitAll}
        autoComplete="on"
        onClick={handleChange}
      >
        <Input
          another={'autoFocus'}
          className={errors.name && classes.errorInput}
          onBlur={handleBlur}
          name="name"
          elementType="text"
          value={values.name}
          placeholder={props.name}
          onChange={handleChange}
        />
        <Input
          className={errors.phone && classes.errorInput}
          onBlur={handleBlur}
          name="phone"
          elementType="text"
          value={values.phone}
          placeholder={props.phone}
          onChange={handleChange}
        />
        <Input
          className={errors.email && classes.errorInput}
          onBlur={handleBlur}
          name="email"
          elementType="email"
          value={values.email}
          placeholder={props.mail}
          onChange={handleChange}
        />
        <Input
          className={classes.textarea}
          onBlur={handleBlur}
          name="message"
          elementType="textarea"
          value={values.message}
          placeholder={props.details}
          onChange={handleChange}
        />
        <button
          disabled={!submitted}
          type="submit"
          className={submitted ? classes.submitButton : classes.disableButton}
          onClick={() => notify()}
        >
          {!sent ? props.button : props.sentButton}
        </button>
      </form>
      <ToastContainer
        style={{ marginTop: 100 }}
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
      />
    </div>
  )
}

export default ContactForm
