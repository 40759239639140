export default function validateAuth(values) {
  let errors = {}
  if (!values.email) {
    errors.email = 'Obowiązkowy'
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
    errors.email = 'Pamiętaj, by Twój e-mail był prawidłowy'
  }
  //Phone Errors
  // if (!values.phone) {
  //   errors.phone = 'Obowiązkowy'
  // } else if (values.phone.length < 8 || ![0 - 9]) {
  //   errors.phone = 'Pamiętaj, by podać nam prawidłowy numer telefonu'
  // }
  return errors
}
