import React from 'react'
import { withNamespaces } from 'react-i18next'
import '../../i18n'
import { graphql, useStaticQuery } from 'gatsby'

import classes from './Contact.module.scss'
import Layout from '../../hoc/layout/layout'
import SEO from '../../hoc/seo'
import Grid from '@material-ui/core/Grid'
import { animateScroll as scroll } from 'react-scroll'
import IosMail from 'react-ionicons/lib/IosMail'
import MdHome from 'react-ionicons/lib/MdHome'
import MdPhonePortrait from 'react-ionicons/lib/MdPhonePortrait'
import MdMail from 'react-ionicons/lib/MdMail'
import ContactForm from './ContactForm/ContactForm'
import logo from '../../assets/images/logo/pgc-kwadrat.png'
// import { GlobalStateContext } from '../../context/GlobalContextProvider'

const Contact = ({ t }) => {
  // const state = useContext(GlobalStateContext)
  const data = useStaticQuery(graphql`
    query {
      allDirectusMainpageTranslation {
        edges {
          node {
            ulica
            miasto
            telefon
            email
          }
        }
      }
    }
  `)
  const telefon = `tel:${data.allDirectusMainpageTranslation.edges[0].node.telefon}`
  const mail = `mailto:${data.allDirectusMainpageTranslation.edges[0].node.email}`
  return (
    <Layout>
      <SEO title={t('contact')} />

      <div className={classes.contact}>
        <Grid container className={classes.mainContainer} style={{ margin: 0 }}>
          <Grid item xs={12} className={classes.halfHeader}>
            <div className={classes.contentHeader_first}>
              <div className={classes.contentBox}>
                <h1>{t('headerKontakt')}</h1>
              </div>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            className={(classes.halfHeader, classes.contentHeader_second)}
          >
            <Grid container style={{ maxWidth: 1480, margin: 'auto' }}>
              <Grid item xs={12} md={6}>
                <div
                  role="button"
                  tabIndex={0}
                  className={classes.arrowIcon}
                  onClick={() => scroll.scrollTo(210)}
                  onKeyDown={() => scroll.scrollTo(210)}
                >
                  <IosMail fontSize="36" />
                </div>
                <div className={classes.contactForm}>
                  <h3>{t('formCall')}</h3>
                  <ContactForm
                    name={t('formPerson')}
                    phone={t('formPhone')}
                    mail={t('formEmail')}
                    details={t('formDetails')}
                    button={t('formWyslij')}
                    thank={t('formThank')}
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className={classes.contactContent}>
                  <div className={classes.contactField}>
                    <div className={classes.contactCell}>
                      <img
                        src={logo}
                        alt="PGLogo"
                        style={{
                          width: 130,
                          marginBottom: 15,
                        }}
                      />
                    </div>
                    <div className={classes.contactCell}>
                      <MdHome color="white" fontSize="80" />
                      <a
                        target="_blank"
                        href="https://goo.gl/maps/KGu2531LYec4zoLM6"
                        rel="noopener noreferrer"
                      >
                        {
                          data.allDirectusMainpageTranslation.edges[0].node
                            .ulica
                        }
                        <br />
                        {
                          data.allDirectusMainpageTranslation.edges[0].node
                            .miasto
                        }
                      </a>
                    </div>
                    <div className={classes.contactCell}>
                      <MdPhonePortrait color="white" fontSize="80" />
                      <a id="hyperlink" href={telefon}>
                        {
                          data.allDirectusMainpageTranslation.edges[0].node
                            .telefon
                        }
                      </a>
                    </div>
                    <div className={classes.contactCell}>
                      <MdMail color="white" fontSize="80" />
                      <a target="_blank" href={mail} rel="noopener noreferrer">
                        {
                          data.allDirectusMainpageTranslation.edges[0].node
                            .email
                        }
                      </a>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Layout>
  )
}

export default withNamespaces()(Contact)
