import React from 'react'

import classes from './Input.module.scss'

const Input = props => {
  let inputElement = null

  const inputStyle = {
    width: '100%',
    marginBottom: 10,
    height: 46,
    border: '1px solid #474747',
    borderRadius: '20px',
    padding: 15,
    fontSize: 19,
    textAlign: 'left',
    fontFamily: 'inherit',
  }

  const inputDetails = {
    display: 'table-cell',
    height: 86,
    maxHeight: 80,
    minHeight: 76,
    maxWidth: '100%',
    width: '100%',
    marginBottom: 10,
    border: '1px solid #474747',
    borderRadius: '20px',
    padding: 15,
    fontSize: 19,
    textAlign: 'left',
    fontFamily: 'inherit',
  }

  switch (props.elementType) {
    case 'input':
      inputElement = (
        <input
          {...props.another}
          className={props.className}
          onBlur={props.onBlur}
          value={props.value}
          onChange={props.onChange}
          type={props.type}
          name={props.name}
          style={inputStyle}
          placeholder={props.placeholder}
        />
      )
      break
    case 'textarea':
      inputElement = (
        <textarea
          className={props.className}
          onBlur={props.onBlur}
          value={props.value}
          onChange={props.onChange}
          type={props.type}
          name={props.name}
          style={inputDetails}
          placeholder={props.placeholder}
        />
      )
      break
    default:
      inputElement = (
        <input
          className={props.className}
          onBlur={props.onBlur}
          value={props.value}
          onChange={props.onChange}
          type={props.type}
          name={props.name}
          style={inputStyle}
          placeholder={props.placeholder}
        />
      )
  }

  return (
    <div className={classes.Input}>
      <label className={classes.label}>{props.label}</label>
      {inputElement}
    </div>
  )
}

export default Input
