import { useState, useEffect } from 'react'
import axios from 'axios'
import { navigate } from 'gatsby'

const useFormValidation = (initialState, validate) => {
  const [values, setValues] = useState(initialState)
  const [errors, setErrors] = useState({})
  const [submitted, setSubmitted] = useState(false)

  useEffect(() => {
    if (errors) {
      const noErrors = Object.keys(errors).length === 0
      if (noErrors) {
        setSubmitted(true)
      } else {
        setSubmitted(false)
      }
    }
  }, [errors])

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.email]: event.target.value,
      [event.target.name]: event.target.value,
      [event.target.phone]: event.target.value,
      [event.target.message]: event.target.value,
    })
    const validationErrors = validate(values)
    setErrors(validationErrors)
    if (!validationErrors) {
      setSubmitted(true)
    }
  }
  const handleBlur = () => {
    const validationErrors = validate(values)
    setErrors(validationErrors)
  }

  const handleSubmit = e => {
    e.preventDefault()
    if (values.email !== '' && values.name !== '') {
      console.log('Wysłano!')
      setValues(initialState)
      setTimeout(() => navigate('/'), 4000)
      axios({
        method: 'POST',
        url: 'https://pgconsmailer.herokuapp.com/',
        data: {
          name: values.name,
          phone: values.phone,
          email: values.email,
          message: values.message,
        },
      })
        .then(() => {
          navigate('/blog')
          console.log('Wysłano!')
        })
        .catch(error => {
          console.log('Coś poszło źle!')
        })
    }
  }
  return {
    handleChange,
    values,
    handleSubmit,
    handleBlur,
    errors,
    submitted,
  }
}

export default useFormValidation
